import RatesGraphEvolution from '@pretto/bricks/website/rates/components/RatesGraphEvolution'

import { BlocRow } from '@pretto/website/src/templates/blocs/components/BlocRow'
import { RatesAnalysis } from '@pretto/website/src/templates/blocs/components/RatesAnalysis'
import { Author } from '@pretto/website/src/templates/blocs/types/Author.type'
import { RatesPayload } from '@pretto/website/src/templates/blocs/types/RatesPayload.type'
import { ratesEvolution } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesEvolution'

type ProfilRowProps = {
  analysisRowDuration?: string
  analysisRowIsReversed?: boolean
  analysisRowRegion: string
  author: Author
  rates: RatesPayload
}

export const RatesAnalysisRow: React.FC<ProfilRowProps> = ({
  analysisRowDuration,
  analysisRowRegion,
  analysisRowIsReversed,
  author,
  rates,
  ...props
}) => {
  const evolutionGraph = {
    component: RatesGraphEvolution as React.FC,
    props: () => ratesEvolution(rates.marketRatesRegionHistory, analysisRowRegion),
  }

  const RatesAnalysisProps = {
    defaultDuration: analysisRowDuration ? parseInt(analysisRowDuration, 10) : undefined,
    evolutionGraph,
    author,
  }

  return (
    <BlocRow isReversed={analysisRowIsReversed} {...props}>
      <RatesAnalysis {...RatesAnalysisProps} />
    </BlocRow>
  )
}
